<template>
  <div>
    <div class="d-flex justify-content-start">
      <h3 class="mr-1">
        <strong>FORMULARIO:</strong>
      </h3>
      <h4>REGISTRO DE QUEJA O DENUNCIA POR PRESUNTO HOSTIGAMIENTO SEXUAL EN LA UNIA
        <span>
          <b-button
            variant="success"
            size="sm"
            class="ml-1"
            @click="irAformulario"
          >
            ir al formulario
          </b-button>
        </span></h4>

    </div>
    <RespuestasFormularioIndex
      v-if="idFormulario"
      :id-formulario="idFormulario"
    /></div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import RespuestasFormularioIndex from '@/views/administrador/gestion-formulario/nuevo-formulario/respuestas/RespuestasFormularioIndex.vue'

export default {
  components: {
    BButton,
    RespuestasFormularioIndex,
  },
  data() {
    return {
      idFormulario: 0,
    }
  },
  created() {
    this.$http.get('/formulario/HOSTIGAMIENTO-DENUNCIA/buscar')
      .then(res => {
        this.idFormulario = res.data.id
      })
  },
  methods: {
    irAformulario() {
      const routeData = this.$router.resolve({ name: 'vista-anonima-x', params: { id_formulariox: this.idFormulario } })
      window.open(routeData.href, '_blank')
    },
  },
}
</script>

<style>

</style>
